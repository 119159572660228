module.exports = [
  {
    name: '潜力测试',
    name2: 'DISC PERSONALITY TEST',
    key: 'Personality_color_test',
    option: ['A', 'B', 'C', 'D'],
    description: '用“红、蓝、黄、绿”四色代替人的性格类型，借助一幅幅美妙的图画来解析多变的人生；通过对“性格色彩密码”的解读，帮助你学会以“有‘色’眼睛”洞察人性，增强对人生的洞察力。',
    EnglishText: ``,
    background: `性格色彩测试模型源自DISC模型。20世纪20年代，
    美国心理学家威廉.莫尔顿.马斯顿创建了一个理论来解释人的情绪反应，
    在此之前，这种工作主要局限在对于精神病患者或精神失常人群的研究，而马斯顿博士则希望扩大这个研究范围，
    以运用于心理健康的普通人群，因此，马斯顿博士将他的理论构建为一个体系，即：The Emotions of Normal People“正常人的情绪”。 
    为了检验他的理论，马斯顿博士需要采用某种心理测评的方式来衡量人群的情绪反映－－“人格特征”，因此，他采用了四个他认为是非常典型的人格特质因子，
    即Dominance－支配，Influence－影响，Steady－稳健，以及Compliance－服从。而APESK基于DISC模型，正是代表了这四个英文单词的首字母。 
    性格色彩测试模型用“红、蓝、黄、绿”四色代替DISC的支配，稳健，影响，服从。这样，DISC性格类型可以借助一幅幅美妙的图画来解析多变的人生。`,
    questionList: [
      {
        question: '关于人生观，我的内心其实是:',
        A: '希望能够有尽量多的人生体验，所以会有非常多样化的想法。',
        B: '在小心合理的基础上，谨慎地确定自己的目标，一旦确定会坚定不移地去做。',
        C: '更加注重的是取得一切有可能的成就。',
        D: '宁愿剔除风险而享受平静或现状。',
      },
      {
        question: '如果爬山旅游，在下山回来的路线选择上，我更在乎:',
        A: '好玩有趣，所以宁愿新路线回巢。',
        B: '安全稳妥，所以宁愿原路线返回。',
        C: '挑战困难，所以宁愿新路线回巢。',
        D: '方便省心，所以宁愿原路线返回。',
      },
      {
        question: '通常在表达一件事情上，我更看重:',
        A: '说话给对方感受到的强烈印象。',
        B: '说话表述的准确程度。',
        C: '说话所能达到的最终目标。',
        D: '说话后周围的人际感受是否舒服。',
      },
      {
        question: '在生命的大多数时候，我的内心其实更加欣喜于和希望多些:',
        A: '刺激。',
        B: '安全。',
        C: '挑战。',
        D: '稳定。',
      },
      {
        question: '我认为自己在情感上的基本特点是:',
        A: '情绪多变，经常情绪波动。',
        B: '外表上自我抑制能力强，但内心感情起伏极大，一旦挫伤难以平复。',
        C: '感情不拖泥带水，较为直接，只是一旦不稳定，容易激动和发怒。',
        D: '天性情绪四平八稳。',
      },
      {
        question: '我认为自己在整个人生中，除了工作以外，在控制欲上面，我:',
        A: '没有控制欲，只有感染带动他人的欲望，但自控能力不算强。',
        B: '用规则来保持我对自己的控制和对他人的要求。',
        C: '内心是有控制欲和希望别人服从我的。',
        D: ' 不会有任何兴趣去影响别人，也不愿意别人来管控我。',
      },
      {
        question: '当与情人交往时，我倾向于着重:',
        A: '兴趣上的相容性，一起做喜欢的事情，对他的爱意溢于言表。',
        B: '思想上的相容性，体贴入微，对他的需求很敏感。',
        C: '智慧上的相容性，沟通重要的想法，客观地讨论辩论事情。',
        D: ' 和谐上的相容性，包容理解另一半的不同观点。',
      },
      {
        question: '在人际交往时，我:',
        A: '心态开放，可以快速建立起友谊和人际关系。',
        B: '非常审慎缓慢地进入，一旦认为是朋友，便长久地维持。',
        C: '希望在人际关系中占据主导地位。',
        D: '顺其自然，不温不火，相对被动。',
      },
      {
        question: '我认为自己大多数时候更是:',
        A: '感情丰富的人。',
        B: '思路清晰的人。',
        C: '办事麻利的人。',
        D: '心态平静的人。',
      },
      {
        question: '通常我完成任务的方式是:',
        A: '经常会赶在最后期限前完成。',
        B: '自己做，精确地做，不要麻烦别人。',
        C: '先做，快速做。',
        D: '使用传统的方法，需要时从他人处得到帮忙。',
      },
      {
        question: '如果有人深深惹恼我时，我:',
        A: '内心感到受伤，认为没有原谅的可能，可最终很多时候还是会原谅对方。',
        B: '深深感到愤怒，如此之深怎可忘记?我会牢记，同时未来完全避开那个家伙。',
        C: '会火冒三丈，并且内心期望有机会狠狠地回应打击。',
        D: '我会避免摊牌，因为那还不到那个地步，那个人多行不义必自毙，或者自己再去找新朋友。',
      },
      {
        question: '在人际关系中，我最在意的是:',
        A: '得到他人的赞美和欢迎。',
        B: '得到他人的理解和欣赏。',
        C: '得到他人的感激和尊敬。',
        D: '得到他人的尊重和接纳。',
      },
      {
        question: '在工作上，我表现出来更多的是:',
        A: '充满热忧，有很多想法且很有灵性。',
        B: '心思细腻，完美精确，而且为人可靠。',
        C: '坚强而直截了当，而且有推动力。',
        D: '有耐心，适应性强而且善于协调。',
      },
      {
        question: '我过往的老师最有可能对我的评价是:',
        A: '情绪起伏大，善于表达和抒发情感。',
        B: '严格保护自己的私密，有时会显得孤独或是不合群。',
        C: '动作敏捷又独立，并且喜欢自己做事情。',
        D: '看起来安稳轻松，反应度偏低，比较温和。',
      },
      {
        question: '朋友对我的评价最有可能的是:',
        A: '喜欢对朋友述说事情，也有能量说服别人去做事。',
        B: '能够提出很多周全的问题，而且需要许多精细的解说。',
        C: '愿意直言表达想法，有时会直率而犀利地谈论不喜欢的人、事、物。',
        D: '通常与他人一起是多听少说。',
      },
      {
        question: '在帮助他人的问题上，我倾向于:',
        A: '多一事不如少一事，但若他来找我，那我定会帮他。',
        B: '值得帮助的人应该帮助，锦上添花犹胜雪中送炭。',
        C: '无关者何必要帮，但我若承诺，必欲完之而后释然。',
        D: '虽无英雄打虎之胆，却有自告奋勇之心。',
      },
      {
        question: '面对他人对自己的赞美，我的本能反应是:',
        A: '没有也无所谓，特别欣喜那也不至于。',
        B: '我不需要那些无关痛痒的赞美，宁可他们欣赏我的能力。',
        C: '有点怀疑对方是否认真或者立即回避众人的关注。',
        D: '赞美总是一件令人心情非常愉悦的事。',
      },
      {
        question: '面对生活的现状，我的行为习惯更加倾向于:',
        A: '外面怎么变化与我无关，我觉得自己这样还不错。',
        B: '这个世界如果我没什么进步，别人就会进步，所以我需要不停地前进。',
        C: '在所有的问题未发生之前，就应该尽量想好所有的可能性。',
        D: '每天的生活开心快乐最重要。',
      },
      {
        question: '对于规则，我内心的态度是:',
        A: '不愿违反规则，但可能因为松散而无法达到规则的要求。',
        B: '打破规则，希望由自己来制定规则而不是遵守规则。',
        C: '严格遵守规则，并且竭尽全力做到规则内的最好。',
        D: '不喜被规则束缚，不按规则出牌会觉得新鲜有趣。',
      },
      {
        question: '我认为自己在行为上的基本特点是:',
        A: '慢条斯理，办事按部就班，能与周围的人协调一致。',
        B: '目标明确，集中精力为实现目标而努力，善于抓住核心要点。',
        C: '慎重小心，为做好预防及善后，会不惜一切而尽心操劳。',
        D: '丰富跃动，不喜欢制度和约束，倾向于快速反应。',
      },
      {
        question: '在面对压力时，我比较倾向于选用:',
        A: '眼不见为净地化解压力。',
        B: '压力越大抵抗力越大。',
        C: '和别人讲也不一定有用，压力在自己的内心慢慢地咀嚼。',
        D: '本能地回避压力，回避不掉就用各种方法来宣泄出去。',
      },
      {
        question: '当结束一段刻骨铭心的感情时，我会:',
        A: '非常难受，可是日子总还是要过的，时间会冲淡一切的。',
        B: '虽然觉得受伤，但一旦下定决心，就会努力把过去的影子摔掉。',
        C: '深陷在悲伤的情绪中，在相当长的时期里难以自拔，也不愿再接受新的人。',
        D: '痛不欲生，需要找朋友倾诉或者找渠道发泄，寻求化解之道。',
      },
      {
        question: '面对他人的倾诉，我回顾自己大多时候本能上倾向于:',
        A: '认同并理解对方感受。',
        B: '做出一些定论或判断。',
        C: '给予一些分析或推理。',
        D: '发表一些评论或意见。',
      },
      {
        question: '我在以下哪个群体中较感满足?',
        A: '能心平气和最终大家达成一致结论的。',
        B: '能彼此展开充分激烈的辩论。',
        C: '能详细讨论事情的好坏和影响的。',
        D: '能随意无拘束地自由散谈，同时又很开心。',
      },
      {
        question: '在内心的真实想法里，我觉得工作:',
        A: '如果不必有压力，可以让我做我熟悉的工作那就不错。',
        B: '应该以最快的速度完成，且争取去完成更多的任务。',
        C: '要么不做，要做就做到最好。',
        D: '如果能将乐趣融合在里面那就太棒了，不过如果不喜欢的工作实在没劲。',
      },
      {
        question: '如果我是领导，我内心更希望在部属心目中，我是:',
        A: '可以亲近的和善于为他们着想的。',
        B: '有很强的能力和富有领导力的。',
        C: '公平公正且足以信赖的。',
        D: '被他们喜欢并且觉得富有感召力的。',
      },
      {
        question: '我希望得到的认同方式是:',
        A: '无所谓别人是否认同。',
        B: '精英群体的认同最重要。',
        C: '只要我认同的人或者我在乎的人的认同就可以了。',
        D: '希望得到所有大众的认同。',
      },
      {
        question: '当我还是个孩子的时候，我:',
        A: '不太会积极尝试新事物，通常比较喜欢旧有的和熟悉的。',
        B: '是孩子王，大家经常听我的决定。',
        C: '害羞见生人，有意识地回避。',
        D: '调皮可爱，在大部分的情况下是乐观而又热心的。',
      },
      {
        question: '如果我是父母，我也许是:',
        A: '不愿干涉子女或者容易被说动的。',
        B: '严厉的或者直接给予方向性指点的。',
        C: '用行动代替语言来表示关爱或者高要求的。',
        D: '愿意陪伴孩子一起玩的，孩子的朋友们所喜欢和欢迎的。',
      },
      {
        question: '以下有四组格言，哪组里符合我感觉的数目最多?',
        A: `最深刻的真理是最简单和最平凡的。\n
            要在人世间取得成功必须大智若愚。\n
            好脾气是一个人在社交中所能穿着的最佳服饰。\n
            知足是人生在世最大的幸福。`,
        B: `走自己的路，让人家去说吧。\n
            虽然世界充满了苦难，但是苦难总是能战胜的。\n
            有所成就是人生唯一的真正的乐趣。\n
            对我而言解决一个问题和享受一个假期一样好。`,
        C: `一个不注意小事情的人，永远不会成功大事业。\n
            理性是灵魂中最高贵的因素。\n
            切忌浮夸铺张。与其说得过分，不如说得不全。\n
            谨慎比大胆要有力量得多。`,
        D: `与其在死的时候握着一大把钱，还不如活时活得丰富多彩。\n
            任何时候都要最真实地对待你自己，这比什么都重要。\n
            使生活变成幻想，再把幻想化为现实。\n
            幸福不在于拥有金钱，而在于获得成就时的喜悦以及产生创造力的激情。`,
      },
    ],
  },
]
